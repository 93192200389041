import React, {Component} from "react";
import Panel from "../../Panel";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ProfileField from "../Profile/ProfileField";
import Button from '@material-ui/core/Button';
import API from "../../Auth/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {BiRightArrow} from "react-icons/bi";
import queryString from "query-string";

export default class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      accountDelete: false,
      data: {
        mobile_number: ''
      },
      formData: {
        code: '',
        email: '',
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.setState({
      data: this.user,
    });
    this.checkToken();
    this.loadData();
  }

  checkToken = () => {
    const user = JSON.parse(localStorage.getItem('newUser'));
    const values = queryString.parse(this.props.location.search);
    const {formData} = this.state;
    formData.email = (!values.name && (user && user.email)) ? user.email : values.name;
    formData.code = (values && values.token ) ? values.token : '';
    this.setState({
      formData
    }, () => {
      if (formData.email && formData.code) {
        this.loadData(formData)
      }
    });
  }

  loadData() {
    this.setState({
      loading: true,
    });
    API.get(`/api/v1/me`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })
  }

  handleCloseDelete = () => {
    this.setState({
      accountDelete: false,
    });
  };

  accountDelete = () => {
    this.setState({
      loading: true,
    });
    /*
    API.delete(`/wp-json/wp/v2/users/me?reassign=1&force=true`)
      .then((res) => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })
     */
  };

  render() {
    {/*
    if (!this.state.data.verify) {
      return (
        <Panel
          loading={this.state.loading}
          pageTitle={'Settings'}
          {...this.props}
        >

        </Panel>
      )
    }
    */}

    let field = [
      {
        id: 'mobilenumber',
        label: 'Mobile Number',
        name: 'mobile_number',
        type: 'tel',
        value: (this.state.data && this.state.data.mobile_number) && this.state.data.mobile_number,
        xs: 12,
        md: 6,
      },
      {
        id: 'password',
        label: 'Password',
        name: 'password',
        type: 'password',
        value: '',
        xs: 12,
        md: 6,
      },
    ];

    let accountDelete = () => {
      if (this.state.accountDelete) {
        return (
          <>
            <Dialog
              open={this.state.accountDelete}
              onClose={this.handleCloseDelete}
              aria-labelledby="form-dialog-title"
              maxWidth={'md'}
            >
              <DialogTitle id="form-dialog-title">Delete account</DialogTitle>
              <DialogContent>
                Are you sure want to delete the account?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDelete}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.accountDelete}
                  type="submit"
                  color="primary"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
    };

    return (
      <Panel
        loading={this.state.loading}
        pageTitle={'Settings'}
        {...this.props}
      >

        <Grid item xs={12}>
          <Box className={'contentPaper'}>

            <Box component="div">
              <Typography component="h3" variant="h6" color="primary" gutterBottom>
                Subscripton
              </Typography>
              <Typography component="h4" variant="h5" gutterBottom style={{margin: '1em 0'}}>
                Monthly at [10] per mouth
              </Typography>
              <Typography component="p">
                <Button
                  variant="contained"
                  color="primary"
                  href={'#'}
                >
                  Click to upgrade
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
              </Typography>
            </Box>

            <Grid container spacing={3} style={{padding: '3em 0'}}>
              {
                field.map((object, i) => {
                  return (
                    <ProfileField
                      key={i}
                      loading={this.state.loading}
                      userID={this.state.data.id}
                      update={() => {
                        this.loadData()
                      }}
                      object={object}
                    />
                  );
                })
              }
            </Grid>

            <Box component="div">
              <Typography component="h3" variant="h6" color="primary" gutterBottom>
                FREEZE ACCOUNT <small>(You will have 90 days to ininstate)</small>
              </Typography>

              <Typography component="p">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      accountDelete: true,
                    });
                  }}
                >
                  Delete account
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
                {accountDelete()}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Panel>
    );
  }

}
