import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
//import API from '../../Auth/api';
import BusinessAreaTagBtn from "./BusinessAreaTagBtn";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class BusinessArea extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: '',
      data: {},
      tags: {},
      x_tags: {},
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    /*
    API.get(`/wp-json/wp/v2/tags`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  };

  updateData = () => {
    this.setState({
      loading: true,
    });
    const url = `/wp-json/wp/v2/posts/${this.props.post}`;
    const obj = {
      'tags': this.state.tags,
      'x_tags': this.state.x_tags
    }
    /*
    API.post(url, obj)
      .then(() => {
        window.location.assign(`/albums`);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: error.message,
          });
          console.log(error.message);
        }
      })

     */
  }

  handleUpdate = (index, id, name) => {
    const tags = this.state.tags;
    tags[index] = id;
    const x_tags = this.state.x_tags;
    x_tags[index] = name;

    this.setState({
      tags,
      x_tags,
    });
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {
    return (
      <>

        <Grid item xs={12}>
          <Paper className={'contentPaper'} style={{margin: '1.5em 0'}}>
            <Box component="div">
              <Typography component="h3" variant="h6" color="primary" gutterBottom>
                Choose your area of business
              </Typography>

              <Box component="p" pt={1}>
                Select what type of business you ara of 'skip' this section
              </Box>

              <Box component="div" py={3}>
                {this.renderError()}

                {
                  (this.state.data.length > 0) ?
                    this.state.data.map((val, i) => {
                      return (
                        <BusinessAreaTagBtn
                          obj={val}
                          key={i}
                          index={i}
                          update={this.handleUpdate}
                        />)
                    })
                    : null
                }
              </Box>

              <Button
                disabled={this.state.loading}
                fullWidth
                color="primary"
                variant="contained"
                onClick={this.updateData}
              >
                Next
              </Button>
            </Box>
          </Paper>
        </Grid>

      </>
    );
  }
}
