import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../../Auth/api";
import serialize from "form-serialize";
import {BiRightArrow} from "react-icons/bi";

export default class NoCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      formData: {
        company_name: '',
      },
      submitted: false,
    };
    this.form = React.createRef();
  }

  loadData = (obj) => {
    this.setState({
      loading: true,
      error: '',
    });

    let data = {
      company_name: obj.company_name,
    }

    API.post(`/api/v1/my-company`, data)
      .then((res) => {
        let user = JSON.parse(localStorage.getItem('user'));
        user.company_id = res.data.data.id;
        localStorage.setItem('user', JSON.stringify(user));
        this.setState({
          loading: false,
        }, () => {
          return this.props.update(res);
        });
      })
      .catch((error) => {
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error : error.message,
        });
      })
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    this.checkCompanyName(obj);
    event.stopPropagation();
  };

  checkCompanyName = (obj) => {
    this.loadData(obj, true);
    /*
    API.get(`/wp-json/wp/v2/posts?slug=${slugify(obj.company_name)}`)
      .then((res) => {
        if (!res.data || res.data.length > 0) {
          return this.setState({
            loading: false,
            error: 'Incorrect Business Name or Business Name already exists',
          });
        }
        this.loadData(obj, true);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
          return this.setState({
            loading: false,
            error: error.data.message,
          });
        }
      })

     */
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  handleChange = (event) => {
    const {formData} = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData,
      error: '',
    });
  };

  render() {
    const {formData} = this.state;
    return (
      <>
        <Grid item xs={12}>
          <div className={'contentPaper'} style={{margin: '1.5em 0'}}>

            <Box component="div">
              <Box component="p" pt={1}>
                Please create a company.
              </Box>
            </Box>

            <ValidatorForm
              noValidate
              autoComplete='off'
              id={'form'}
              instantValidate={false}
              onSubmit={(e) => {
                this.handleSubmit(e)
              }}
              className={'loginForm'}
              ref={this.form}
              method="POST"
            >

              {this.renderError()}
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <TextValidator
                    required
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="company_name"
                    label="Your Business Name"
                    name="company_name"
                    type="text"
                    placeholder="Your Business Name"
                    disabled={this.state.loading}
                    value={formData.company_name || ''}
                    onChange={this.handleChange}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.state.loading}
                  >
                    Save
                    <BiRightArrow style={{marginLeft: '.5em'}}/>
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </Grid>

      </>
    );
  }
}
