import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import './style.scss'
import {Link as RouterLink} from "react-router-dom";

export default class AlbumShareMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userMenu: null,
    }
  }

  handleClose = () => {
    this.setState({
      userMenu: null,
    });
  }

  render() {

    return (
      <>
        <Button
          style={{marginLeft: '1em'}}
          disabled={this.props.loading}
          onClick={(e) => {
            this.setState({
              userMenu: e.currentTarget,
            })
          }}
          variant="outlined"
          color="primary"
        >
          Share
        </Button>

        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={this.state.userMenu}
          open={(!!this.state.userMenu)}
          onClose={this.handleClose}
        >
          <MenuItem>
            Share this with
          </MenuItem>
          <MenuItem>
            <FacebookShareButton
              url={this.props.url}
              quote={this.props.title}
              title={this.props.title}
              description={this.props.content}
              hashtag={this.props.content}
            >
              <ListItemIcon>
                <FacebookIcon size={16}/>
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Facebook
              </Typography>
            </FacebookShareButton>
          </MenuItem>
          <MenuItem>
            <LinkedinShareButton
              url={this.props.url}
              title={this.props.title}
              summary={this.props.content}
              media={(this.props.images) && this.props.images[0]}
              source={''}
            >
              <ListItemIcon>
                <LinkedinIcon size={16}/>
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Linkedin
              </Typography>
            </LinkedinShareButton>
          </MenuItem>
          <MenuItem>
            <PinterestShareButton
              url={this.props.url}
              media={(this.props.images) && this.props.images[0]}
              description={this.props.content}
            >
              <ListItemIcon>
                <PinterestIcon size={16}/>
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Pinterest
              </Typography>
            </PinterestShareButton>
          </MenuItem>
          <MenuItem>
            <TwitterShareButton
              url={this.props.url}
              media={(this.props.images) && this.props.images[0]}
              title={this.props.title}
              via={this.props.content}
              hashtags={[this.props.title]}
              related={[this.props.title]}
            >
              <ListItemIcon>
                <TwitterIcon size={16}/>
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Twitter
              </Typography>
            </TwitterShareButton>
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={`/send-to-client/${this.props.match.params.id}`}
          >
            <ListItemIcon>
              <EmailIcon size={16}/>
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Send to client
            </Typography>
          </MenuItem>
        </Menu>
      </>

    );
  }
}
