import React, {Component} from "react";
import './style.scss'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import SwipeableViews from "react-swipeable-views";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import serialize from "form-serialize";
import API from "../../Auth/api";
import AlbumAddImageSelect from "./AlbumAddImageSelect";
import {BiRightArrow} from "react-icons/bi";

export default class AlbumAddImagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: null,
      value: 0,
      error: '',
      data: {},
    };
    this.user = this.props.auth.getUser();
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  loadData = () => {
    this.setState({
      loading: true,
      error: '',
      data: {},
    });

    API.get(`/api/v1/my-album-file`)
      .then((res) => {
        this.setState({
          loading: false,
          error: '',
          data: res.data.data,
          file: Array(res.data.data.length),
        });
      })
      .catch((error) => {
        console.log('album error', error);
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong album'
        });
      })
  }

  uploadImage = () => {
    this.setState({
      loading: true,
      error: '',
    });
    const formData = new FormData();
    formData.append('file', this.state.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    API.post(`/api/v1/my-album-file/${this.props.match.params.id}`, formData, config)
      .then((res) => {
        this.setState({
          loading: false,
          error: '',
        });
        this.props.update([...JSON.parse(res.data.data), ...this.props.obj]);
        this.handleClose();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: (
              error
              && error.response
              && error.response.data
              && error.response.data.error
            ) ? error.response.data.error
              : 'wrong album'
          });
          console.log(error.message);
        }
      })
  }

  onChangeFile(e) {
    let file = e.target.files[0];
    this.setState({
      file: file,
    });
  }

  updateSelect = (index, obj, select) => {
    let data = this.state.file;
    data[index] = (select) && obj;
    this.setState({
      file: data,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.value === 1 && this.state.file) {
      const arr = this.state.file.filter(element => element !== null);
      this.props.update([...arr, ...this.props.obj]);
      return this.handleClose();
    }
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    if (this.state.file) {
      this.uploadImage(obj)
    }
    event.stopPropagation();
  };

  handleClose = () => {
    this.setState({
      loading: false,
      file: null,
      value: 0,
      error: '',
      data: {},
    }, () => {
      this.props.handleClose();
    });
  }

  render() {
    const {value} = this.state;
    const handleChange = (event, index) => {
      if (index === 1) {
        this.loadData();
      }
      this.setState({
        value: index,
        file: null,
        error: '',
      });
    };

    const handleChangeIndex = (index) => {
      if (index === 1) {
        this.loadData();
      }
      this.setState({
        value: index,
        file: null,
        error: '',
      });
    };

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
      >
        <DialogTitle id="form-dialog-title">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={`Upload File`}/>
            <Tab label={`Files Library`}/>
          </Tabs>
        </DialogTitle>

        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <DialogContent
            value={value}
            index={0}
            hidden={value !== 0}
            id={`full-width-tabpanel-0`}
            aria-labelledby={`full-width-tab-0`}
          >
            {this.renderError()}

            <Input
              fullWidth
              className={'prField'}
              margin="dense"
              id={'filename'}
              label={'Upload File'}
              name={'filename'}
              type={'file'}
              placeholder={'Upload File'}
              onChange={(e) => this.onChangeFile(e)}
            />

          </DialogContent>

          <DialogContent
            value={value}
            index={1}
            hidden={value !== 1}
            id={`full-width-tabpanel-1`}
            aria-labelledby={`full-width-tab-1`}
          >
            {this.renderError()}

            <div className={'AlbumImagesList'}>
              {
                (this.state.data.length > 0) &&
                this.state.data.map((object, i) => {
                  return (
                    <AlbumAddImageSelect
                      {...this.props}
                      key={i}
                      index={i}
                      object={object}
                      updateSelect={this.updateSelect}
                    />
                  )
                })
              }
            </div>

          </DialogContent>
        </SwipeableViews>

        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary"
            variant="contained"
          >
            Cancel
            <BiRightArrow style={{marginLeft: '.5em'}}/>
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={this.handleSubmit}
            type="submit"
            color="primary"
            variant="contained"
          >
            Add
            <BiRightArrow style={{marginLeft: '.5em'}}/>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
