export default class Auth {
  constructor() {
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUser = this.getUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  isAuthenticated() {
    return localStorage.getItem('isToken');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  login(res) {
    localStorage.clear();
    sessionStorage.clear();
    const token = res.token;
    localStorage.setItem('isToken', (!!token));
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(res.user));
    window.location.replace('/');
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
}
