import React, {Component} from "react";
import {MdPanoramaFishEye, MdCheckCircle} from "react-icons/all";
import Box from "@material-ui/core/Box";
import './style.scss';

export default class AlbumAddImageSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
    this.user = this.props.auth.getUser();
  }

  render() {
    return (
      <div className={'gallery-attachment'}>
        <div
          className={'margin'}
          onClick={() => {
            this.setState({
              selected: !this.state.selected,
            }, () => {
              this.props.updateSelect(this.props.index, this.props.object, this.state.selected);
            });
          }}
        >
          <div className={'thumbnail'}>
            <img src={this.props.object.url} alt={''}/>
          </div>
          <Box hidden={this.state.selected} className={'AlbumAddImageSelectIcon'}>
            <MdPanoramaFishEye/>
          </Box>
          <Box hidden={!this.state.selected} className={'AlbumAddImageSelectIcon'}>
            <MdCheckCircle color={'primary'}/>
          </Box>
        </div>
      </div>
    );
  }
}
