import React, {Component} from "react";
import './style.css';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FacebookLogin from 'react-facebook-login/dist/facebook-login'
import {MdArrowForward, FaFacebookF, MdLoop} from "react-icons/all";
import {Alert} from "@material-ui/lab";
//import API from "../../Auth/api";

export default class FBAccounts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  responseData = (response) => {
    this.setState({
      loading: true,
      error: null,
    });

    /*
    const obj = {
      'acf_fields': {
        'fb_user_token': (!response.status) ? response : {},
      }
    }
    API.put(`/wp-json/acf/v3/users/${this.props.user.user_id}`, obj)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
        }, () => {
          this.props.update(res.data.acf.fb_user_token);
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })
     */
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (

        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {
    const fb_user = this.props.acf;

    return (
      <>
        {this.renderError()}
        <ListItem>
          {
            (!this.props.loading) ?
              (!fb_user || Object.keys(fb_user).length < 1) ?
                <FacebookLogin
                  appId="563115331057978"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={this.responseData}
                  render={renderProps => (
                    <Button
                      fullWidth
                      onClick={renderProps.onClick}
                    >
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={1}>
                          <Avatar>
                            <i className={'fb-Badge-big'}><FaFacebookF
                              className={'fb-Badge-big-icon'}/></i>
                          </Avatar>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="body1"
                            className={'socialBtnText'}
                          >
                            Connect to Facebook Page
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <MdArrowForward/>
                        </Grid>
                      </Grid>
                    </Button>
                  )}
                />
                :
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={2} sm={1}>
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={<i className={'fb-Badge-small'}><FaFacebookF
                        className={'fb-Badge-small-icon'}/></i>}
                    >
                      <Avatar
                        alt={fb_user.name}
                        src={fb_user.picture.data.url}
                      />
                    </Badge>
                  </Grid>

                  <Grid item xs={8} sm={7}>
                    <Typography variant="body1">
                      {fb_user.name}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      Facebook Page
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <FacebookLogin
                      appId="563115331057978"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      render={renderProps => (
                        <Button
                          disabled={this.state.loading}
                          fullWidth
                          onClick={renderProps.onClick}
                        >
                          Reconnect
                          <MdLoop className={'socialReconnectIcon'}/>
                        </Button>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Button
                      disabled={this.state.loading}
                      fullWidth
                      color="primary"
                      onClick={
                        () => {
                          this.responseData({})
                        }
                      }
                    >Remove</Button>
                  </Grid>
                </Grid>
              :
              <>
                <CircularProgress/>
              </>
          }
        </ListItem>
        <Divider/>
      </>
    )
  }
}
