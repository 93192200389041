import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ImgDashboard from "../../../assets/images/img-02.png";
import Button from "@material-ui/core/Button";

export default class ContentNoSocial extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>

        <Grid item xs={12}>
          <div style={{margin: '1.5em 0'}}>
            <Box component="div">
              <Box component="p" pt={1}>
                First thing is first is to connect your social media accounts now so you don't have to later.
              </Box>
              <Box component="p" pt={1}>
                By connecting your accounts will help automate your marketing and bring more success to your
                campaigns.
              </Box>
            </Box>
          </div>

          <div className={'contentPaper'} style={{margin: '1.5em 0', textAlign: 'center'}}>

            <img src={ImgDashboard} alt={''}/>

            <Typography component="h2" variant="h6" style={{padding: '1em 0'}}>
              Let's get you account set up!
            </Typography>

            <Button
              size={"large"}
              href={`/social-accounts`}
              color="primary"
              variant="contained"
              style={{marginRight: '.5em'}}
            >
              Connect Your Social Accounts
            </Button>
            or
            <Button
              size={"large"}
              href={`/albums`}
              color="primary"
              style={{marginLeft: '.5em'}}
            >
              skip to do later
            </Button>
          </div>
        </Grid>

      </>
    );
  }
}
