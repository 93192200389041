import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
//import API from "../../Auth/api";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class SharingSwitch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
  }

  loadData = (obj) => {
    this.setState({
      loading: true,
      error: '',
    });
    //obj = {'acf_fields': obj}
   // const url = `/wp-json/acf/v3/posts/${this.props.postID}/`;
    /*
    API.post(url, obj)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.update(res.data);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: error.message,
          });
          console.log(error.message);
        }
      })

     */
  };

  handleChange = (event) => {
    let obj = {}
    obj[event.target.name] = Boolean(event.target.checked);
    this.loadData(obj);
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {

    return (
      <>
        <Grid item xs={12} sm={6}
              style={{paddingBottom: '1em'}}>
          <Grid
            container
            alignItems="center"
          >
            <Grid item xs={9}>
              {this.props.object.label}<br/>
              {(this.props.object.value) ?
                <Button href="#text-buttons" color="primary" size="small">
                  Preview
                </Button>
                :
                <Button href="#text-buttons" color="primary" size="small">
                  Sign-in to post
                </Button>
              }
            </Grid>
            <Grid item xs={3}>
              <Switch
                disabled={this.props.loading || this.state.loading}
                checked={this.props.object.value}
                onChange={this.handleChange}
                color="primary"
                name={this.props.object.name}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
