import React, {Component} from "react";
import './style.scss'
import IconButton from "@material-ui/core/IconButton";
import {MdCancel} from "react-icons/all";

export default class AlbumImagesItem extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.user = this.props.auth.getUser();
  }

  render() {
    return (
      <div
        className={'gallery-attachment'}
      >
        {/*
        <div className={'num'}>
          {(this.props.index === 0) ? 'Primary' : this.props.index + 1}
        </div>
        */}
        <div
          className={'margin'}
          onClick={() => {
            this.props.preview(this.props.object.url);
          }}
        >
          <div className={'thumbnail'}>
            <img src={this.props.object.url} alt={''}/>
          </div>
        </div>
        {
          (this.props.edit) &&
          <div className={'action'}>
            <IconButton
              aria-label="toggle"
              color={"primary"}
              onClick={() => {
                this.props.preview(null);
                this.props.delete(this.props.index);
              }}
            >
              <MdCancel/>
            </IconButton>
          </div>
        }
      </div>
    )
  }
}

