import React, {Component} from "react";
import AlbumAddImagesModal from "./AlbumAddImagesModal";
import {MdCloudUpload} from "react-icons/all";
import './style.scss'

export default class AlbumAddImagesBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.user = this.props.auth.getUser();
  }

  render() {
    return (
      <div
        className={`gallery-attachment ${(this.props.edit) ? 'active-edit' : ''}`}
      >
        {
          (this.props.edit) &&
          <span className={'another-text'}>Add another image</span>
        }
        <div
          className={'margin'}
          onClick={() => {
            (this.props.edit) &&
            this.setState({
              open: true,
            });
          }}
        >
          <div className={'thumbnail'}>
            <i><MdCloudUpload/></i>
            <span>{(!this.props.edit) ? 'Still no images' : <>Upload Files <b>JPG, PNG</b></>}</span>
          </div>
        </div>
        <AlbumAddImagesModal
          {...this.props}
          open={this.state.open}
          handleClose={() => {
            this.setState({
              open: false,
            });
          }}
        />
      </div>
    )
  }
}
