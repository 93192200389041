import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ProfileDialogs from "./ProfileDialogs";
import Avatar from '@material-ui/core/Avatar';
import Tags from "./tags";
import {Alert} from "@material-ui/lab";
import './Profile.css'
import Collapse from "@material-ui/core/Collapse";
import {MdCheckCircle, MdContentCopy, MdCreate} from "react-icons/all";

export default class ProfileField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      copyText: null,
    };
  }

  handleCopy = () => {
    let copyText = document.getElementById(`copy_${this.props.object.id}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    this.setState({
      copyText: copyText.value,
    }, () => {
      setTimeout(() => {
        this.setState({
          copyText: null,
        })
      }, 3000);
    });
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  render() {
    let object = this.props.object;

    if (object.type === 'tags') {
      return (<Tags {...this.props} />);
    }

    return (
      <Grid item xs={object.xs} md={object.md}>
        {(object.type === 'image' || (object.type === 'textarea' && !object.copy)) ?
          <>
            {
              (object.type === 'image') &&
              <div className={'profileCompanyLogoBox'}>
                <div className={'profileCompanyLogoBoxWrap'}>
                  <Avatar alt="" src={object.value} className={'profileCompanyLogo'}/>
                  <IconButton
                    className={'profileCompanyLogo-btn'}
                    aria-label="toggle"
                    onClick={() => {
                      this.handleOpen();
                    }}
                  >
                    <MdCreate/>
                  </IconButton>
                </div>
              </div>
            }

            {
              (object.type === 'textarea' && !object.copy) &&
              <FormControl fullWidth className={'textarea-field'}>
                <InputLabel htmlFor={object.id}>{object.label}</InputLabel>
                <IconButton
                  aria-label="toggle"
                  color={'primary'}
                  onClick={() => {
                    this.handleOpen()
                  }}
                >
                  <MdCreate/>
                </IconButton>
                <div dangerouslySetInnerHTML={{__html: object.value}}/>
              </FormControl>
            }
          </>
          :
          <>
            <Collapse in={(!!this.state.copyText)}>
              <Alert iconMapping={{success: <MdCheckCircle fontSize="inherit"/>}}>
                url copied. Now you can: ctrl+v: {this.state.copyText}
              </Alert>
            </Collapse>
            <FormControl fullWidth>
              <InputLabel htmlFor={object.id}>{object.label}</InputLabel>
              <Input
                fullWidth
                className={'prField'}
                margin="dense"
                id={object.id}
                label={object.label}
                name={object.name}
                type={object.type}
                placeholder={object.label}
                disabled
                value={(object.value_1 && object.value_2) ? `${object.value_1} ${object.value_2}` : object.value || ''}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle"
                      color={'primary'}
                      onClick={() => {
                        (!object.copy) ?
                          this.handleOpen()
                          :
                          this.handleCopy();
                      }}
                    >
                      {
                        (!object.copy) ?
                          <MdCreate/>
                          :
                          <MdContentCopy/>
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                (object.copy) &&
                <input
                  style={{position: 'absolute', zIndex: -1,}}
                  id={`copy_${object.id}`}
                  type={'text'}
                  value={(object.value_1 && object.value_2) ? `${object.value_1} ${object.value_2}` : object.value || ''}
                />
              }
            </FormControl>
          </>
        }

        {
          (this.state.open) &&
          <ProfileDialogs
            open={this.state.open}
            handleClose={() => {
              this.setState({
                open: false,
              });
            }}
            {...this.props}
          />
        }
      </Grid>
    );
  }
}
