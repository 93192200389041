import React, {Component} from 'react';
import Panel from "../../Panel";
import Auth from '../../../components/Auth/Auth';
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

const auth = new Auth();

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    localStorage.clear();
    auth.logout();
    window.location.assign('/');
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error" className={'width-100'}>
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          className={'width-100'}
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {
    return (
      <Panel
        loading={this.state.loading}
        pageTitle={''}
        {...this.props}
        disabledAlbums={true}
        disabledProfile={true}
      >
        <>
          {this.renderError()}
        </>
      </Panel>
    );
  }
}
