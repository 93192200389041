import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Panel from "../../Panel";
import ProfileField from "./ProfileField";
import './Profile.css'
import NoCompany from "../Dashboard/NoCompany";
import API from "../../Auth/api";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      error: '',
      posts: {
        business_name: '',
        company_album: '',
        company_logo: '',
        company_name: '',
        company_number: '',
        company_phone: '',
        company_slug: '',
        company_url: '',
        description_services: '',
        email: '',
        id: '',
        postcode: '',
        skills_set: [],
        user_id: '',
        establish_date: '',
        code: '',
        overview: 'overview'
      }
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.setState({
      data: this.user,
    });
    this.loadPosts();
  }

  loadPosts = () => {
    this.setState({
      loading: true,
    });
    API.get(`/api/v1/my-company`)
      .catch(error => {
          this.setState({
            loading: false,
            error: (
              error
              && error.response
              && error.response.data
              && error.response.data.error
            ) ? error.response.data.error
              : 'wrong company'
          });
          return null
        }
      )
      .then(res => {
        if (res) {
          let post = res.data.data;
          post.skills_set = (post.skills_set) ? JSON.parse(post.skills_set) : [];
          this.setState({
            loading: false,
            posts: res.data.data,
          });
        }
      })
  }

  render() {
    if (!this.state.data.company_id) {
      return (
        <Panel loading={this.state.loading} pageTitle={'Profile'} {...this.props}>
          <NoCompany
            {...this.props}
            loading={this.state.loading}
            data={this.state.data}
            update={(res) => {
              let data = this.state.data
              data.company_id = res.data.data.id;
              this.setState({
                data,
              }, () => {
                this.loadPosts();
              });
            }}
          />
        </Panel>
      )
    }

    console.log(this.state.data)

    let field = [
      {
        id: 'logo',
        label: 'Company Logo',
        name: 'company_logo',
        type: 'image',
        dataType: 'posts',
        value: (!this.state.posts || !this.state.posts.company_logo) ? '' : this.state.posts.company_logo,
        xs: 12,
        md: 12,
      },
      {
        id: 'username',
        label: 'Business Name',
        name: 'company_name',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.company_name) ? this.state.posts.company_name : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'fullname',
        label: 'Full Name',
        label_1: 'First Name',
        label_2: 'Last Name',
        name: 'fullname',
        name_1: 'first_name',
        name_2: 'last_name',
        type: 'text',
        dataType: 'user',
        value: `${this.state.data.first_name || ''} ${this.state.data.last_name || ''}`,
        value_1: this.state.data.first_name || '',
        value_2: this.state.data.last_name || '',
        xs: 12,
        md: 6,
      },
      {
        id: 'Email address',
        label: 'Email address',
        name: 'email',
        type: 'text',
        //dataType: 'user',
        dataType: 'posts',
        value: this.state.posts.email || '',
        xs: 12,
        md: 6,
      },
      {
        id: 'company_phone_number',
        label: 'Company Phone Number',
        name: 'company_phone',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.company_phone) ? this.state.posts.company_phone : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'Postcode',
        label: 'Postcode',
        name: 'postcode',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.postcode) ? this.state.posts.postcode : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'company_number',
        label: 'Company Number',
        name: 'company_number',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.company_number) ? this.state.posts.company_number : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'address',
        label: 'Address',
        name: 'address',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.address) ? this.state.posts.address : '',
        xs: 12,
        md: 12,
      },
      {
        id: 'description_of_services',
        label: 'Description of Services',
        name: 'description_services',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.description_services) ? this.state.posts.description_services : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'establish_date',
        label: 'Establish date',
        name: 'establish_date',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.establish_date) ? this.state.posts.establish_date : '',
        xs: 12,
        md: 6,
      },
      {
        id: 'url',
        label: 'Your work URL',
        name: 'company_url',
        type: 'text',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.company_url) ? this.state.posts.company_url : `https://traca247.com/company/${(this.state.posts && this.state.posts.company_slug) ? this.state.posts.company_slug : ''}`,
        xs: 12,
        md: 6,
      },
      {
        id: 'Accreditations',
        label: 'Accreditations',
        name: 'Accreditations',
        type: 'text',
        dataType: 'posts',
        copy: true,
        value: `https://traca247.com/company/${(this.state.posts && this.state.posts.company_slug) ? this.state.posts.company_slug : ''}`,
        xs: 12,
        md: 6,
      },
      {
        id: 'Skills',
        label: 'Skills Set',
        name: 'skills_set',
        type: 'tags',
        dataType: 'posts',
        value: (this.state.posts && this.state.posts.skills_set) ? this.state.posts.skills_set : [1],
        xs: 12,
        md: 12,
      },
      {
        id: 'code',
        label: 'Embed Code for your website',
        name: 'code',
        type: 'textarea',
        copy: true,
        value: this.state.data.code || '',
        xs: 12,
        md: 12,
      },

      {
        id: 'overview',
        label: 'Overview',
        name: 'overview',
        type: 'textarea',
        dataType: 'posts',
        value: this.state.posts.overview || '',
        xs: 12,
        md: 12,
      },
    ];

    return (
      <Panel loading={this.state.loading} pageTitle={'Profile'} {...this.props}>
        <Grid item xs={12}>
          <div className={'contentPaper'}>
            <Grid container spacing={3}>
              {
                field.map((object, i) => {
                  return (
                    <ProfileField
                      key={i}
                      loading={this.state.loading}
                      userID={this.state.data.id}
                      postID={(this.state.posts && this.state.posts.id) && this.state.posts.id}
                      update={(data, type) => {
                        if (type === 'posts') {
                          data.skills_set = (data.skills_set) ? JSON.parse(data.skills_set) : [];
                          this.setState({
                            posts: data,
                          });
                        } else {
                          this.setState({
                            data: data,
                          });
                        }
                      }}
                      object={object}
                    />
                  );
                })
              }
            </Grid>
          </div>
        </Grid>
      </Panel>
    );
  }

}
