import React, {Component} from "react";
//import API from "../../Auth/api";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {IoMdCreate} from "react-icons/all";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import serialize from "form-serialize";

export default class SharingCaption extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.caption || '',
      loading: false,
      open: false,
      error: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.caption !== this.props.caption) {
      this.setState({
        value: this.props.caption || '',
      });
    }
  }

  loadData = (obj) => {
    this.setState({
      loading: true,
      error: '',
    });
    //obj = {'acf_fields': obj}
    //const url = `/wp-json/acf/v3/posts/${this.props.postID}/`;
    /*
    API.post(url, obj)
      .then((res) => {
        this.setState({
          loading: false,
          open: false,
        });
        this.props.update(res.data);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: error.message,
          });
          console.log(error.message);
        }
      })

     */
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    this.loadData(obj, true);
    event.stopPropagation();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {
    return (
      <div>
        {this.props.caption || 'Write a caption ...'}
        <IconButton
          disabled={this.props.loading || this.state.loading}
          aria-label="toggle"
          onClick={() => {
            this.setState({
              open: true,
            });
          }}
        >
          <IoMdCreate/>
        </IconButton>

        <Dialog
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          aria-labelledby="form-dialog-title"
          maxWidth={'md'}
        >
          <ValidatorForm
            noValidate
            autoComplete='off'
            id={'form'}
            instantValidate={false}
            onSubmit={(e) => {
              this.handleSubmit(e)
            }}
            className={'loginForm'}
            ref={this.form}
            method="POST"
          >
            <DialogTitle
              id="form-dialog-title">Edit Caption</DialogTitle>
            <DialogContent>

              {this.renderError()}

              <TextValidator
                required
                fullWidth
                autoFocus
                className={'prField'}
                margin="dense"
                name={'caption'}
                disabled={this.props.loading || this.state.loading}
                type={'textarea'}
                label={'Caption'}
                placeholder={'Write a caption ...'}
                value={this.state.value || ''}
                rows={4}
                multiline={true}
                onChange={(e) => {
                  this.setState({
                    value: e.target.value,
                    error: '',
                  });
                }}
                validators={['required']}
                errorMessages={['This field is required']}
              />

            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.props.handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.loading}
                type="submit"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>

          </ValidatorForm>
        </Dialog>
      </div>
    );
  }
}
