import React, {lazy, Suspense} from 'react'
import {Switch} from 'react-router-dom'
import {MakeRouteWithSubRoutes} from './makeRouteWithSubRoutes'
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Sharing from "../pages/Sharing";
import SendToClient from "../pages/SendToClient";
import Albums from "../pages/Albums";
import Logout from "../pages/Logout";
import Settings from "../pages/Settings";
import SocialAccounts from "../pages/SocialAccounts";
import NewAlbum from "../pages/NewAlbum";
import Album from "../pages/Album";
import NewAlbumImages from "../pages/NewAlbum/NewAlbumImages";

const Notifications = lazy(() => import('../pages/Notifications'));
const MainPage = lazy(() => import('../pages/MainPage'));
const PageNotFoundComponent = lazy(() => import('../pages/PageNotFound'));
const Login = lazy(() => import('../pages/Login'));
const SignUp = lazy(() => import('../pages/Login/SignUp'));
const VerifyEmail = lazy(() => import('../pages/Login/VerifyEmail'));
const Congratulations = lazy(() => import('../pages/Login/Congratulations'));
const ForgotPassword = lazy(() => import('../pages/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Login/ResetPassword'));
const Company = lazy(() => import('../pages/Company'));
const CompanyProfile = lazy(() => import('../pages/Company/CompanyProfile'));
const CompanyAlbum = lazy(() => import('../pages/Company/CompanyAlbum'));

const routesNotLoged = [
  {
    path: '/',
    exact: true,
    component: MainPage
  },
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    path: "/signup",
    component: SignUp
  },
  {
    path: '/lost-password',
    component: ForgotPassword
  },
  {
    path: '/congratulations',
    component: Congratulations
  },
  {
    path: '/reset-password',
    search: "?token=token",
    component: ResetPassword
  },
  {
    path: '/verify',
    search: "?token=token",
    component: VerifyEmail
  },
  {
    path: '/company/:id/:aid',
    component: CompanyAlbum,
  },
  {
    path: '/company/:id',
    component: CompanyProfile,
  },
  {
    path: '/company',
    component: Company,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

const routesLoged = [
  {
    path: "/",
    exact: true,
    component: Dashboard
  },
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard
  },
  {
    path: "/profile",
    exact: true,
    component: Profile
  },
  {
    path: "/sharing/:id",
    component: Sharing
  },
  {
    path: "/send-to-client/:id",
    component: SendToClient
  },
  {
    path: "/album/:id",
    component: Album
  },
  {
    path: "/albums",
    component: Albums
  },
  {
    path: "/new-album/:id",
    component: NewAlbumImages
  },
  {
    path: "/new-album",
    exact: true,
    component: NewAlbum
  },
  {
    path: '/social-accounts',
    component: SocialAccounts,
  },
  {
    path: "/settings",
    exact: true,
    component: Settings
  },
  {
    path: '/verify',
    search: "?token=token",
    component: Settings
  },
  {
    path: '/notifications',
    component: Notifications
  },
  {
    path: '/company/:id/:aid',
    component: CompanyAlbum,
  },
  {
    path: '/company/:id',
    component: CompanyProfile,
  },
  {
    path: '/company',
    component: Company,
  },
  {
    path: "/logout",
    component: Logout
  },
  {
    path: "**",
    redirectTo: '/',
    component: PageNotFoundComponent,
  },
];

export const Routes = (props) => {
  const authenticated = props.auth.isAuthenticated();
  let routes = routesNotLoged;
  if (authenticated === 'true') {
    routes = routesLoged
  }

  return (
    <Suspense fallback={<div/>}>
      <Switch>
        {
          routes.map(
            (route, index) => <MakeRouteWithSubRoutes key={index} {...route} {...props} />
          )
        }
      </Switch>
    </Suspense>
  )
};
