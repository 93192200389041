import React, {Component} from "react";
import './style.scss'
import IconButton from "@material-ui/core/IconButton";
import {MdCancel} from "react-icons/all";

export default class AlbumImagesPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.user = this.props.auth.getUser();
  }

  render() {

    if (!this.props.previewObj) {
      return null;
    }

    return (
      <div className={'AlbumImagesPreview'}>
        <img src={this.props.previewObj} alt={''}/>
        <div className={'action'}>
          <IconButton
            aria-label="toggle"
            onClick={this.props.close}
          >
            <MdCancel/>
          </IconButton>
        </div>
      </div>
    )
  }
}
