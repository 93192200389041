import React, {Component} from "react";
import './Profile.css'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import {IoMdCreate} from "react-icons/all";

export default class TagsAutocomplete extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const p = this.props
    return (
      <Autocomplete
        multiple
        disabled={this.props.disabled}
        id="size-small-standard-multi"
        size="small"
        options={this.props.tagOptions}
        getOptionLabel={(option) => (option) && option.title}
        value={this.props.value}
        className={`${(this.props.disabled) ? 'showTagsAutoAdornment' : ''}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={this.props.object.label}
            placeholder="Add Skills"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.props.loading ?
                    <CircularProgress color="inherit" size={20}/>
                    :
                    (this.props.disabled) &&
                      <IconButton
                        className={'skilsInputAdornment'}
                        aria-label="toggle"
                        onClick={this.props.handleOpen}
                        color={'primary'}
                      >
                        <IoMdCreate/>
                      </IconButton>
                  }
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        onChange={(e, newValue) => {
          p.handleChange(newValue);
        }}
      />
    );
  }
}
