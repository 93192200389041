import React, {Component} from "react";
import './style.css';
import API from "../../Auth/api";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Panel from "../../Panel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AlbumImagesBox from "../Album/AlbumImagesBox";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import serialize from "form-serialize";
import Button from "@material-ui/core/Button";
import {IoIosArrowBack} from "react-icons/all";
import {Link as RouterLink} from "react-router-dom";
import {BiRightArrow} from "react-icons/bi";

export default class SendToClient extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: true,
      value: {
        name: '',
        note: '',
        email: '',
        mobile_number: '',
        message: '',
      },
      formData: {
        title: '',
        description: '',
        status: 'publish',
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      loading: true,
      error: '',
    });
    API.get(`/api/v1/my-album/${this.props.match.params.id}`)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        data.images = JSON.parse(data.images);
        this.setState({
          loading: false,
          formData: data,
        });
      })
      .catch((error) => {
        console.log('createCompany error', error);
        return this.setState({
          loading: false,
          error: error.message,
        });
      })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    this.updateData(obj);
    event.stopPropagation();
  };

  handleChange = (event) => {
    const {value} = this.state;
    value[event.target.name] = event.target.value;
    this.setState({
      value,
      error: '',
    });
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  updateImage = (data) => {
    if (this.state.edit) {
      let formData = {...this.state.formData};
      formData.images = data;
      this.setState({
        formData: formData,
      });
    }
  }

  render() {
    let formData = {...this.state.formData};
    let value = {...this.state.value};
    let images = (formData.images) ? [...formData.images] : [];

    return (
      <Panel
        loading={this.state.loading}
        pageTitle={`Send Album: ${this.state.formData.title}` || 'Send Album'}
        {...this.props}
      >
        <Grid item xs={12}>

          <Grid
            container
            alignItems="center"
            style={{paddingBottom: '1em'}}
          >
            <Grid item xs={2} sm={1}>
              <IconButton
                component={RouterLink}
                to={`/album/${this.props.match.params.id}`}
                aria-label="toggle"
                disabled={this.state.loading}
              >
                <IoIosArrowBack/>
              </IconButton>
            </Grid>
          </Grid>

          <div className={'contentPaper'}>

            <ValidatorForm
              noValidate
              autoComplete='off'
              id={'form'}
              instantValidate={false}
              onSubmit={(e) => {
                this.handleSubmit(e)
              }}
              className={'albumForm'}
              ref={this.form}
              method="POST"
            >

              <Grid container alignItems="center">
                <Grid item xs={8} sm={10}>
                  <TextValidator
                    required
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    name={'name'}
                    disabled={this.state.loading}
                    type={'text'}
                    label={'Add person\'s name here'}
                    placeholder={'Add person\'s name here'}
                    value={value.name || ''}
                    onChange={this.handleChange}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item xs={8} sm={10}>
                  <TextValidator
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    name={'note'}
                    disabled={this.state.loading}
                    type={'text'}
                    label={'Add note (optional)'}
                    placeholder={'Add note (optional)'}
                    value={value.note || ''}
                    onChange={this.handleChange}
                    errorMessages={['This field is required']}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item xs={8} sm={10}>
                  <TextValidator
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    name={'email'}
                    disabled={this.state.loading}
                    type={'text'}
                    label={'Add email address (optional)'}
                    placeholder={'Add email address (optional)'}
                    value={value.email || ''}
                    onChange={this.handleChange}
                    validators={['email']}
                    errorMessages={['This field is required']}
                  />
                </Grid>
              </Grid>

              <div style={{paddingTop: '1em'}}>
                or
              </div>

              <Grid container alignItems="center">
                <Grid item xs={8} sm={10}>
                  <TextValidator
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    name={'mobile_number'}
                    disabled={this.state.loading}
                    type={'textarea'}
                    label={'Mobile Number'}
                    placeholder={'Mobile Number'}
                    value={value.mobile_number || ''}
                    onChange={this.handleChange}
                    errorMessages={['This field is required']}
                  />
                </Grid>
              </Grid>

              <div style={{paddingTop: '2em'}}>
                <AlbumImagesBox
                  {...this.props}
                  edit={this.state.edit}
                  obj={(images) ? images : {}}
                  loading={this.state.loading}
                  delete={(obj) => {
                    if (this.state.edit) {
                      images.splice(obj, 1);
                    }
                  }}
                  update={this.updateImage}
                />
              </div>

              <Grid container alignItems="center">
                <Grid item xs={8} sm={10}>
                  <TextValidator
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    name={'message'}
                    disabled={this.state.loading}
                    type={'textarea'}
                    label={'Message to the client (optional)'}
                    placeholder={'It would be great if you would share this post on your social network. All the best'}
                    value={value.message || ''}
                    rows={4}
                    multiline={true}
                    onChange={this.handleChange}
                    errorMessages={['This field is required']}
                  />
                </Grid>
              </Grid>

              <div style={{textAlign: 'right', paddingTop: '2em'}}>
                <Button
                  disabled={this.state.loading}
                  color="secondary"
                  variant="contained"
                  component={RouterLink}
                  to={`/album/${this.props.match.params.id}`}
                >
                  Cancel
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
                <Button
                  style={{marginLeft: '1em'}}
                  disabled={this.state.loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Send
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </Grid>
      </Panel>
    )
  }
}

