import React, {Component} from "react";
import Panel from "../../Panel";
import Grid from '@material-ui/core/Grid';
import API from "../../Auth/api";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import serialize from "form-serialize";
import Box from "@material-ui/core/Box";
import './style.css'
import {BiRightArrow} from "react-icons/bi";

export default class NewAlbum extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: {
        title: '',
        description: '',
        status: 'publish',
      },
    };
    this.user = this.props.auth.getUser();
  }

  loadData(obj) {
    this.setState({
      loading: true,
      error: '',
    });
    obj.company = [this.user.company_id];
    API.post(`/api/v1/my-albums`, obj)
      .then((res) => {
        window.location.assign(`/new-album/${res.data.data.id}`);
      })
      .catch((error) => {
        console.log('createCompany error', error);
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong album'
        });
      })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    this.loadData(obj);
    event.stopPropagation();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  handleChange = (event) => {
    const {formData} = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData,
      error: '',
    });
  };

  render() {

    const {formData} = this.state;

    return (
      <Panel loading={this.state.loading} pageTitle={'New Album'} {...this.props}>

        <Grid item xs={12}>
          <div className={'contentPaper'}>

            <ValidatorForm
              noValidate
              autoComplete='off'
              id={'form'}
              instantValidate={false}
              onSubmit={(e) => {
                this.handleSubmit(e)
              }}
              className={'loginForm'}
              ref={this.form}
              method="POST"
            >

              {this.renderError()}

              <TextValidator
                required
                fullWidth
                autoFocus
                margin="dense"
                id={'title'}
                name={'title'}
                disabled={this.state.loading}
                value={formData.title || ''}
                type={'text'}
                label={'Album Name'}
                placeholder={'Give a catchy name to entice people to view and share'}
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={this.handleChange}
              />

              <TextValidator
                fullWidth
                margin="dense"
                id={'description'}
                name={'description'}
                disabled={this.state.loading}
                value={formData.description || ''}
                type={'textarea'}
                label={'Description (optional)'}
                rows={4}
                multiline={true}
                placeholder={'This will show under your album name'}
                onChange={this.handleChange}
              />

              <Box className={'alignRight'} pt={2}>
                <Button
                  disabled={this.state.loading}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
                <Button
                  disabled={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{marginLeft: '.5em'}}
                >
                  Next
                  <BiRightArrow style={{marginLeft: '.5em'}}/>
                </Button>
              </Box>

            </ValidatorForm>
          </div>
        </Grid>

      </Panel>
    );
  }
}
