import React, {Component} from "react";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../../Auth/api";
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './style.scss'
import AlbumsCard from "./AlbumsCard";
import {Box} from "@material-ui/core";
import AlbumsHavent from "./AlbumsHavent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fab from '@material-ui/core/Fab';
import async from "async";
import {Link as RouterLink} from "react-router-dom";

export default class AlbumsContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: 0,
      data: {},
      draft: {},
      company: {}
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    if (this.user.company_id) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      loading: true,
    });

    async.parallel({
      company: (callback) => {
        API.get(`/api/v1/my-company`)
          .then(res => {
            callback(null, res.data);
          }).catch((error) => {
          callback(error, null);
        })
      },
      albums: (callback) => {
        API.get(`/api/v1/my-albums`)
          .then(res => {
            callback(null, res.data);
          }).catch((error) => {
          callback(error, null);
        })
      },
    }, (error, res) => {
      try {
        if (error) {
          this.setState({
            loading: false,
          });
          if (error) {
            console.log(error);
          }
        } else {
          let publish = [];
          let draft = [];
          res.albums.data.forEach((e) => {
            e.images = JSON.parse(e.images);
            if (e.status === 'drafts') {
              draft.push(e)
            } else {
              publish.push(e)
            }
          })

          this.setState({
            loading: false,
            data: publish,
            draft: draft,
            company: res.company.data
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      }
    })
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  render() {
    const {value} = this.state;
    const handleChange = (event, index) => {
      this.setState({
        value: index,
      });
    };

    const handleChangeIndex = (index) => {
      this.setState({
        value: index,
      });
    };

    return (
      <>
        {
          (this.state.loading) &&
          <LinearProgress
            className={'mainLinearProgress'}
            style={{top: '-4px'}}
          />
        }
        <Tabs
          fullWidth
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={'albumsTabsNav'}
        >
          <Tab
            label={`List of albums (${this.state.data.length || 0})`}/>
          <Tab
            label={`Drafts (${this.state.draft.length || 0})`}/>
        </Tabs>

        <SwipeableViews
          className={'albumsTabBox'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Typography
            component={'div'}
            value={value}
            index={0}
            hidden={value !== 0}
            id={`full-width-tabpanel-0`}
            aria-labelledby={`full-width-tab-0`}
          >
            <Box className={'newAlbumBox'}>
              <Fab
                component={RouterLink}
                disabled={this.state.loading}
                size="small"
                color="primary"
                variant="extended"
                to={'/new-album'}
              >
                + Add Album
              </Fab>
            </Box>
            {
              (this.state.data.length > 0) ?
                this.state.data.map((object, i) => {
                  return (
                    <AlbumsCard
                      {...this.props}
                      company={this.state.company}
                      object={object}
                      key={i}
                    />
                  );
                })
                :
                <AlbumsHavent
                  {...this.props}
                  type={'published'}
                  loading={this.state.loading}
                />
            }
          </Typography>

          <Typography
            component={'div'}
            value={value}
            index={1}
            hidden={value !== 1}
            id={`full-width-tabpanel-1`}
            aria-labelledby={`full-width-tab-1`}
          >
            <Box className={'newAlbumBox'}>
              <Fab
                component={RouterLink}
                disabled={this.state.loading}
                size="small"
                color="primary"
                variant={'extended'}
                to={'/new-album'}
              >
                + Add Album
              </Fab>
            </Box>
            {
              (this.state.draft.length > 0) ?
                this.state.draft.map((object, i) => {
                  return (
                    <AlbumsCard
                      {...this.props}
                      company={this.state.company}
                      object={object}
                      key={i}
                    />
                  );
                })
                :
                <AlbumsHavent
                  {...this.props}
                  type={'draft'}
                  loading={this.state.loading}
                />
            }
          </Typography>
        </SwipeableViews>
      </>
    );
  }
}
