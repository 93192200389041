import React, {Component} from "react";
import './style.scss';
import Img02 from '../../../assets/images/img-02.png'
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";

export default class AlbumsHavent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.user = this.props.auth.getUser();
  }

  render() {

    if (this.props.loading) {
      return null
    }

    return (
      <Box style={{margin: '1.5em 0', textAlign: 'center'}}>

        <img src={Img02} alt={''}/>

        <Typography component="h2" variant="h6" style={{padding: '1em 0'}}>
          You haven't {this.props.type} any posts with this account!
        </Typography>

        <p>Any scheduled posts will appear here</p>

      </Box>
    )
  }
}
