import axios from 'axios'

//let url = `http://localhost:8080/`;
let url = 'https://traca247.com/'

if (url.substr(-1) !== '/') {
  url += '/';
}

export const baseURL = url;

const api = axios.create({
  baseURL: baseURL,
});

const token = localStorage.getItem('token');
if (token) {
  api.defaults.headers.common['Authorization'] = token;
}

api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log('err', error);

  if (error.response && error.response.status === 500) {
    if (error.response.data) {
      if (error.response.data.code === "invalid_username") {
        window.location.assign('/logout')
      }
    }
  }

  if (error.response && error.response.status === 404) {
    if (error.response.data) {
      if (error.response.data.code === "rest_user_invalid_id") {
        window.location.assign('/logout')
      }
    }
  }

  if (error.response && error.response.status === 403) {
    window.location.assign('/logout')
  }

  if (error.response && error.response.status === 401) {
    if (error.response.config.url.replace(error.response.config.baseURL, '') !== '/api-auth/') {
      //  window.location.assign('/logout')
    }
  }
  return Promise.reject(error)
});

export default api
