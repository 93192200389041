import React, {Component} from "react";
import './style.css';
import Panel from "../../Panel";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
//import API from "../../Auth/api";
import FBAccounts from "./FBAccounts";
//import InstAccounts from "./InstAccounts";

export default class SocialAccounts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {
        acf: {
          fb_user_token: {},
        }
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    /*
    API.post(`/wp-json/wp/v2/users/me`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })
    */
  }

  render() {

    return (
      <Panel loading={this.state.loading} pageTitle={'Social Accounts'} {...this.props}>
        <Grid item xs={12}>

          <List>
            <Divider/>
            <FBAccounts
              user={this.user}
              acf={this.state.data.acf.fb_user_token}
              loading={this.state.loading}
              update={(newData) => {
                const data = this.state.data;
                data.acf.fb_user_token = newData;
                this.setState({
                  data
                });
              }}
            />

            {/*
            <InstAccounts
              user={this.user}
              acf={this.state.data.acf.fb_user_token}
              loading={this.state.loading}
              update={(newData) => {

              }} />
            */}
          </List>

        </Grid>
      </Panel>
    )

  }
}

