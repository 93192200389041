import React, {Component} from "react";
import Chip from '@material-ui/core/Chip';

export default class BusinessAreaTagBtn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      check: false,
    };
  }

  handleClick = (index, id, name) => {
    this.setState({
      check: !this.state.check,
    }, () => {
      this.props.update(index, (this.state.check) ? id : null, (this.state.check) ? name : null);
    });
  }

  render() {
    return (
      <Chip
        style={{marginRight: '.5em'}}
        color={(this.state.check) ? 'primary' : 'default'}
        label={this.props.obj.name}
        onClick={() => this.handleClick(this.props.index, this.props.obj.id, this.props.obj.name)}
      />
    );
  }
}
