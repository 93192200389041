import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './Profile.css'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Alert} from "@material-ui/lab";
import serialize from "form-serialize";
import API from "../../Auth/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import TagsAutocomplete from "./tagsAutocomplete";
import Input from "@material-ui/core/Input";
import {BiRightArrow} from "react-icons/bi";
import JoditEditor from "jodit-react";

export default class ProfileField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      file: null,
      value: (this.props.object.type === 'tags') ? this.props.value : (this.props.object.value_1) ? this.props.object.value_1 : this.props.object.value || '',
      value_2: this.props.object.value_2 || '',
    };

    this.contentRef = React.createRef();
  }

  loadData = (obj) => {
    this.setState({
      loading: true,
      error: '',
    });
    let url = `/api/v1/me`;
    if (this.props.object.dataType === 'posts') {
      url = `/api/v1/my-company`;
    }

    if (this.props.object.type === 'tags') {
      obj = {
        'skills_set': JSON.stringify(this.state.value.map(x => {
          return x.id
        }))
      }
    }

    API.put(url, obj)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (this.props.object.dataType === 'user') {
          localStorage.setItem('user', JSON.stringify(res.data.data));
        }
        this.props.update(res.data.data, this.props.object.dataType);
        return this.props.handleClose();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: (
              error
              && error.response
              && error.response.data
              && error.response.data.error
            ) ? error.response.data.error
              : 'error',
          });
          console.log(error.message);
        }
      })
  };

  uploadImage = (obj) => {
    this.setState({
      loading: true,
      error: '',
    });
    const formData = new FormData();
    formData.append('file', this.state.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    API.post(`/api/v1/my-company-file`, formData, config)
      .then((res) => {
        obj = {'company_logo': res.data.filename}
        this.loadData(obj, true);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          this.setState({
            loading: false,
            error: error.message,
          });
          console.log(error.message);
        }
      })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    if (this.props.object.type === 'image') {
      if (this.state.file) {
        this.uploadImage(obj)
      }
    } else {
      this.loadData(obj, true);
    }
    event.stopPropagation();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  onChangeFile(e) {
    let file = e.target.files[0];
    this.setState({
      file: file,
    });
  }

  render() {
    let object = this.props.object;
    const config =  {
      readonly: false,
      disablePlugins: "table,tablekeyboardnavigation,tooltip,symbols,sticky,stat,search,resizer,media,limit,justify,iframe",
      buttons: ",,,,,,|,,ul,ol,,,,,font,fontsize,,paragraph,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr",
    }

    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'md'}
        >
          <ValidatorForm
            noValidate
            autoComplete='off'
            id={'form'}
            instantValidate={false}
            onSubmit={(e) => {
              this.handleSubmit(e)
            }}
            className={'loginForm'}
            ref={this.form}
            method="POST"
          >
            <DialogTitle
              id="form-dialog-title">{(object.type === 'image') ? 'Upload' : 'Edit'} {object.label}</DialogTitle>
            <DialogContent>
              {this.renderError()}

              {(
                object.type === 'tags'
                || object.type === 'image'
                || object.type === 'textarea'
              ) ?
                <>
                  {
                    (object.type === 'tags') &&
                    <TagsAutocomplete
                      {...this.props}
                      disabled={false}
                      name={'tags'}
                      tagOptions={this.props.tagOptions}
                      value={this.state.value}
                      loading={this.state.loading || this.props.loading}
                      handleChange={(newValue) => {
                        this.setState({
                          value: newValue,
                          error: '',
                        });
                      }}
                    />
                  }

                  {
                    (object.type === 'image') &&
                    <Input
                      fullWidth
                      className={'prField'}
                      margin="dense"
                      id={object.id}
                      label={object.label}
                      name={'filename'}
                      type={'file'}
                      placeholder={object.label}
                      onChange={(e) => this.onChangeFile(e)}
                    />
                  }

                  {
                    (object.type === 'textarea') &&
                    <JoditEditor
                      ref={this.contentRef}
                      id={object.id}
                      label={object.label}
                      value={this.state.value}
                      name={(object.name_1) ? object.name_1 : object.name}
                      config={config}
                    />
                  }

                </>
                :
                <>
                  <TextValidator
                    required
                    fullWidth
                    autoFocus
                    className={'prField'}
                    margin="dense"
                    id={object.id}
                    name={(object.name_1) ? object.name_1 : object.name}
                    disabled={this.state.loading}
                    type={object.type || 'text'}
                    label={(object.label_1) ? object.label_1 : null}
                    placeholder={(object.label_1) ? object.label_1 : object.label}
                    value={this.state.value}
                    onChange={(e) => {
                      this.setState({
                        value: e.target.value,
                        error: '',
                      });
                    }}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />

                  {
                    (object.name_2) &&
                    <TextValidator
                      required
                      fullWidth
                      className={'prField'}
                      margin="dense"
                      id={object.id}
                      name={object.name_2 || ''}
                      disabled={this.state.loading}
                      type={object.type || 'text'}
                      label={object.label_2 || ''}
                      placeholder={object.label_2 || ''}
                      value={this.state.value_2 || ''}
                      onChange={(e) => {
                        this.setState({
                          value_2: e.target.value,
                          error: '',
                        });
                      }}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  }
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={this.props.handleClose}
                color="secondary"
              >
                Cancel
                <BiRightArrow style={{marginLeft: '.5em'}}/>
              </Button>
              <Button
                variant="contained"
                disabled={this.state.loading}
                type="submit"
                color="primary"
              >
                Save
                <BiRightArrow style={{marginLeft: '.5em'}}/>
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </>
    );
  }
}
