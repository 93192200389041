import React from 'react';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {Routes} from './components/router/'
import {BrowserRouter} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import Auth from "./components/Auth/Auth";

const auth = new Auth()

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#3f45a2',
      main: '#0a44ff',
      dark: '#03062f'
    },
    secondary: {
      light: '#ffbdba',
      main: '#f23d4c',
      dark: '#E6007C'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    }
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes auth={auth}/>
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById('root')
)

reportWebVitals();
serviceWorker.register();
