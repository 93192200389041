import React, {Component} from "react";
import AlbumImagesItem from "./AlbumImagesItem";
import AlbumAddImagesBox from "./AlbumAddImagesBox";
import './style.scss';

export default class AlbumImages extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.user = this.props.auth.getUser();
  }

  render() {

    if (this.props.obj.length > 0) {
      return (
        <>
          {this.props.obj.map((object, i) => {
            return (
              <AlbumImagesItem
                {...this.props}
                key={i}
                index={i}
                object={object}
              />
            )
          })}
          {
            (this.props.edit) &&
            <AlbumAddImagesBox {...this.props}/>
          }
        </>
      )
    }
    return (<AlbumAddImagesBox {...this.props}/>);
  }
}
