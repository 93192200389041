import React, {Component} from "react";
import './style.css';
//import API from "../../Auth/api";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Panel from "../../Panel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import SharingSwitch from "./SharingSwitch";
//import DefImg from "../../../assets/images/reputation.jpg";
import SharingCaption from "./SharingCaption";
import {IoIosArrowBack} from "react-icons/all";
import {Link as RouterLink} from "react-router-dom";

export default class Sharing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: false,
      formData: {
        title: '',
        content: '',
        status: 'publish',
        acf: {
          images: [],
          share_facebook: false,
          share_twitter: false,
          share_instagram: false,
          caption: '',
        },
        x_metadata: {
          images: []
        },
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      loading: true,
      error: '',
    });
    /*
    API.get(`/wp-json/wp/v2/album/${this.props.match.params.id}`)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data));
        data.title = res.data.title.rendered;
        data.content = res.data.content.rendered;
        this.setState({
          loading: false,
          formData: data,
        });
      })
      .catch((error) => {
        console.log('createCompany error', error);
        return this.setState({
          loading: false,
          error: error.message,
        });
      })

     */
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };


  render() {

    let img = ''; // DefImg;
    let field = [
      {
        id: 'share_facebook',
        label: 'Facebook',
        name: 'share_facebook',
        value: (this.state.formData.acf.share_facebook) ? this.state.formData.acf.share_facebook : false,
      },
      {
        id: 'share_twitter',
        label: 'Twitter',
        name: 'share_twitter',
        value: (this.state.formData.acf.share_twitter) ? this.state.formData.acf.share_twitter : false,
      },
      {
        id: 'share_instagram',
        label: 'Instagram',
        name: 'share_instagram',
        value: (this.state.formData.acf.share_instagram) ? this.state.formData.acf.share_instagram : false,
      },
    ];

    if (this.state.formData.acf.images) {
      if (this.state.formData.acf.images.length > 0) {
        img = this.state.formData.acf.images[0];
      }
    }

    return (
      <Panel loading={this.state.loading} pageTitle={''} {...this.props}>
        <Grid item xs={12}>

          <Grid
            container
            alignItems="center"
            style={{paddingBottom: '1em'}}
          >
            <Grid item xs={2} sm={1}>
              <IconButton
                component={RouterLink}
                to={`/album/${this.props.match.params.id}`}
                aria-label="toggle"
                disabled={this.state.loading}
              >
                <IoIosArrowBack/>
              </IconButton>
            </Grid>
            <Grid item xs={10} sm={11}>
              <Typography
                component="h1"
                variant="h5"
                dangerouslySetInnerHTML={{__html: `Share: ${this.state.formData.title}`} || 'Share '}
              />
            </Grid>
          </Grid>

          <Paper className={'contentPaper'}>

            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              <Grid item xs={4} sm={3}>
                <div className={'gallery-attachment'}>
                  <div className={'margin'} style={{margin: 0}}>
                    <div className={'thumbnail'}>
                      <img src={img} alt={''}/>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={8} sm={9}>
                <SharingCaption
                  caption={this.state.formData.acf.caption}
                  loading={this.state.loading}
                  postID={this.props.match.params.id}
                  update={(data) => {
                    let formData = this.state.formData;
                    formData.acf = data.acf
                    this.setState({
                      formData,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Typography component="h5" variant="h6" style={{paddingTop: '1em'}}>
              Add hashtag
            </Typography>


            <Typography component="h5" variant="h6" style={{paddingTop: '1em'}}>
              Also post to
            </Typography>

            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              {
                field.map((object, i) => {
                    return (
                      <SharingSwitch
                        key={i}
                        postID={this.props.match.params.id}
                        loading={this.state.loading}
                        update={(data) => {
                          let formData = this.state.formData;
                          formData.acf = data.acf
                          this.setState({
                            formData,
                          });
                        }}
                        object={object}
                      />
                    )
                  }
                )
              }
              <Grid item xs={12} sm={6}
                    style={{paddingBottom: '1em'}}>
                <Button
                  component={RouterLink}
                  to={`/send-to-client/${this.props.match.params.id}`}
                  color="primary"
                  variant="contained"
                >
                  Send to client
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Panel>
    )
  }
}
