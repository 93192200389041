import React, {Component} from "react";
import './style.css';
import API from "../../Auth/api";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Panel from "../../Panel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AlbumImagesBox from "../Album/AlbumImagesBox";
import {ValidatorForm} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import serialize from "form-serialize";
import {IoIosArrowBack} from "react-icons/all";
import {Redirect} from "react-router-dom";

export default class NewAlbumImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: true,
      redirect: false,
      formData: {
        title: '',
        description: '',
        status: '',
        images: [],
        company_slug: '',
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      loading: true,
      error: '',
    });
    API.get(`/api/v1/my-album/${this.props.match.params.id}`)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        data.images = JSON.parse(data.images);
        this.setState({
          loading: false,
          formData: data,
        });
      })
      .catch((error) => {
        console.log('load album error', error);
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong album'
        });
      })
  }

  updateData(obj) {
    this.setState({
      loading: true,
      error: '',
    });
    API.put(`/api/v1/my-album/${this.props.match.params.id}`, obj)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        data.images = JSON.parse(data.images);
        this.setState({
          edit: false,
          loading: false,
          formData: data,
          redirect: true
        });
      })
      .catch((error) => {
        console.log('create album error', error);
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong album'
        });
      })
  }

  handleSubmit = (event, images) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    obj.images = JSON.stringify(images);
    this.updateData(obj);
    event.stopPropagation();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  handleChange = (event) => {
    let formData = {...this.state.formData};
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData,
      error: '',
    });
  };

  updateImage = (data) => {
    if (this.state.edit) {
      let formData = {...this.state.formData};
      formData.images = data;
      this.setState({
        formData: formData,
      });
    }
  }

  render() {
    let formData = {...this.state.formData};
    let images = (formData.images) ? [...formData.images] : [];

    if (this.state.redirect) {
      return (
        <Redirect
          to={`/album/${this.props.match.params.id}`}
        />
      )
    }

    return (
      <Panel loading={this.state.loading} pageTitle={''} {...this.props}>
        <Grid item xs={12}>

          <Grid
            container
            alignItems="center"
            style={{paddingBottom: '1em'}}
          >
            <Grid item xs={2} sm={1}>
              <IconButton
                component={'a'}
                href={`/album/${this.props.match.params.id}`}
                aria-label="toggle"
                disabled={this.state.loading}
              >
                <IoIosArrowBack/>
              </IconButton>
            </Grid>
            <Grid item xs={10} sm={11}>
              <Typography
                component="h1"
                variant="h5"
                dangerouslySetInnerHTML={{__html: `Images for Album: ${this.state.formData.title || ''}`} || 'Images for Album'}
              />
            </Grid>
          </Grid>

          <div className={'contentPaper'}>
            <ValidatorForm
              noValidate
              autoComplete='off'
              id={'form'}
              instantValidate={false}
              onSubmit={(e) => {
                this.handleSubmit(e, images)
              }}
              className={'albumForm'}
              ref={this.form}
              method="POST"
            >
              <AlbumImagesBox
                {...this.props}
                edit={true}
                obj={(images) ? images : {}}
                loading={this.state.loading}
                delete={(obj) => {
                  if (this.state.edit) {
                    images.splice(obj, 1);
                  }
                }}
                update={this.updateImage}
              />
              <div style={{textAlign: 'right', paddingTop: '2em'}}>
                <Button
                  disabled={this.state.loading}
                  type="reset"
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  style={{marginLeft: '1em'}}
                  disabled={this.state.loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </Grid>
      </Panel>
    )
  }
}

