import React, {Component} from "react";
import './style.scss'
import AlbumImages from "./AlbumImages";
import AlbumImagesPreview from "./AlbumImagesPreview";

export default class AlbumImagesBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: null,
    };
    this.user = this.props.auth.getUser();
  }

  handlePreview = (obj) => {
    this.setState({
      preview: obj,
    });
  }

  render() {
    return (
      <div className={'AlbumImages'}>
        <AlbumImagesPreview
          previewObj={this.state.preview}
          close={() => {
            this.setState({
              preview: null,
            });
          }}
          {...this.props}
        />
        <div className={'AlbumImagesList'}>
          <AlbumImages
            {...this.props}
            preview={this.handlePreview}
          />
        </div>
      </div>
    )
  }
}
