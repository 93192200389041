import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import DefImg from "../../../assets/images/logo_t.png";
import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from '@material-ui/core/CardActionArea';
import Fab from '@material-ui/core/Fab';
import {Link as RouterLink} from "react-router-dom";
import {BiShareAlt, FaTwitter, FaFacebookF, FaInstagram} from "react-icons/all";
import Radio from '@material-ui/core/Radio';

export default class AlbumsCard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.user = this.props.auth.getUser();
  }

  render() {
    const object = this.props.object;
    let img = DefImg;
    let imgLength = 0;

    if (this.props.company && this.props.company.company_logo) {
      img = this.props.company.company_logo;
    }

    if (object.images) {
      if (object.images.length > 0) {
        img = object.images[0].url;
        imgLength = object.images.length;
      }
    }

    return (
      <Card className={'AlbumsCard'}>
        <CardActionArea
          to={`/album/${object.id}`}
          component={RouterLink}
        >
          <Grid container>
            <Grid item xs={3} sm={2}>

              <CardMedia
                image={img}
                title={object.title || ''}
                className={'AlbumsCardMedia'}
              >
                <Badge
                  color="primary"
                  badgeContent={imgLength}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                />
              </CardMedia>

            </Grid>
            <Grid item xs={9} sm={10}>
              <CardContent>

                <Typography
                  omponent="h3"
                  variant="h6"
                  className={'albumViewTitle'}
                  dangerouslySetInnerHTML={{__html: object.title} || ''}
                />

                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Badge
                      color="default"
                      badgeContent={0}
                      className={'viewsBadge'}
                      showZero
                    >
                      <Typography>URL Views</Typography>
                    </Badge>
                  </Grid>
                  <Grid item xs={3} sm={2} className={'center'}>
                    <Fab
                      color="primary"
                      aria-label="add"
                      variant={'round'}
                    >
                      <BiShareAlt
                        style={{
                          fontSize: '2em'
                        }}
                      />
                    </Fab>
                    <p style={{
                      fontSize: '.9em'
                    }}>Shared with:</p>
                  </Grid>
                  <Grid item xs={9} sm={7}>
                    <div className={'share-items-box'}>
                      <div className={'share-item'}>
                        <span className={'iconBox'} />
                        <Radio
                          checked={true}
                          value={true}
                          name="RadioClient"
                          color="primary"
                        />
                      </div>
                      <div className={'share-item'}>
                        <span className={'iconBox twitter'}>
                          <FaTwitter />
                        </span>
                        <Radio
                          checked={object.share_twitter}
                          value={true}
                          name="share_twitter"
                          color="primary"
                        />
                      </div>
                      <div className={'share-item'}>
                        <span className={'iconBox facebook'}>
                          <FaFacebookF />
                        </span>
                        <Radio
                          checked={object.share_facebook}
                          value={true}
                          name="share_facebook"
                          color="primary"
                        />
                      </div>
                      <div className={'share-item'}>
                        <span className={'iconBox instagram'}>
                          <FaInstagram />
                        </span>
                        <Radio
                          checked={object.share_instagram}
                          value={true}
                          name="share_instagram"
                          color="primary"
                        />
                      </div>

                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
