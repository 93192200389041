import React, {Component} from "react";
import Panel from "../../Panel";
//import API from '../../Auth/api';
import ContentNoSocial from "./ContentNoSocial";
import NoCompany from "./NoCompany";
import BusinessArea from './BusinessAreaTags'
import AlbumsContent from "../Albums/AlbumsContent";

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabledAlbums: false,
      disabledProfile: false,
      connect: false,
      data: {},
      posts: null,
      post: null,
      albums: null,
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
     this.loadData();
  }

  loadData() {
    this.setState({
      data: this.props.auth.getUser()
    });
    // this.loadPost();
  }

  loadPost() {
    this.setState({
      loading: true,
    });
    /*
    API.get(`/wp-json/wp/v2/posts/${this.state.data.acf.company_page_id}`)
      .then((res) => {
        this.setState({
          loading: false,
          post: res.data,
          posts: res.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  }

  loadPosts() {
    /*
    API.get(`/wp-json/wp/v2/posts?author=${this.state.data.id}`)
      .then((res) => {
        this.setState({
          loading: (!!res.data[0]),
          posts: res.data[0],
        }, () => {
          if (res.data[0]) {
            this.checkAlbums();
          }
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  }

  checkAlbums() {
    this.setState({
      loading: true,
    });
    /*
    API.get(`/wp-json/wp/v2/company`)
      .then((res) => {
        const check = res.data.find(e => e.slug === this.state.posts.slug);
        if (check) {
          this.setState({
            albums: check,
          }, () => {
            this.updateAcf();
          });
        } else {
          this.addAlbums();
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  }

  addAlbums() {
    const data = {
      name: this.state.posts.title.rendered,
      slug: this.state.posts.slug,
    }
    /*
    API.post(`/wp-json/wp/v2/company`, data)
      .then((res) => {
        this.setState({
          albums: res.data,
        }, () => {
          this.updateAcf();
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  }

  updateAcf() {
    const obj = {
      'acf_fields': {
        'company_name': this.state.posts.title.rendered,
        "company_page_id": this.state.posts.id,
        "company_page_slug": this.state.posts.slug,
        "company_album": this.state.albums.name,
        "company_album_id": this.state.albums.id,
      }
    }
    /*
    API.put(`/wp-json/acf/v3/users/${this.state.data.id}`, obj)
      .then(() => {
        this.loadData();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error) {
          console.log(error);
        }
      })

     */
  }

  render() {

    const content = () => {
      if (this.state.loading) {
        return null
      }

      if (!this.state.data.company_id) {
        return (
          <NoCompany
            {...this.props}
            loading={this.state.loading}
            data={this.state.data}
            update={(res) => {
              let data = this.state.data
              data.company_id = res.data.data.id;
              this.setState({
                data,
              }, () => {
                this.loadPosts();
              });
            }}
          />
        )
      }

      if (this.user.company_page_id && (!this.state.post.tags || this.state.post.tags.length < 1)) {
        return (
          <BusinessArea
            loading={this.state.loading}
            data={this.state.data}
            post={this.user.company_page_id}
          />
        )
      }

      if (this.state.connect) {
        return (
          <AlbumsContent auth={this.props.auth}/>
        )
      }

      return (
        <ContentNoSocial
          {...this.props}
          loading={this.state.loading}
          data={this.state.data}
        />
      )
    }

    return (
      <Panel
        loading={this.state.loading}
        pageTitle={`Welcome ${this.user.first_name || ''}`}
        {...this.props}
        disabledAlbums={this.state.disabledAlbums}
        disabledProfile={this.state.disabledProfile}
      >
        <>
          {content()}
        </>
      </Panel>
    );
  }
}
