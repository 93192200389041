import React, {Component} from "react";
import Panel from "../../Panel";
import Grid from '@material-ui/core/Grid';
import API from "../../Auth/api";
import Typography from "@material-ui/core/Typography";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Link as RouterLink} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import serialize from "form-serialize";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlbumImagesBox from "./AlbumImagesBox";
import './style.scss'
import {IoIosArrowBack, IoMdClose, IoMdCreate, MdCheckCircle, RiFileCopyLine} from "react-icons/all";
import AlbumShareMenu from "./AlbumShareMenu";
import Input from '@material-ui/core/Input';
import Collapse from '@material-ui/core/Collapse';
import {BiRightArrow} from "react-icons/bi";

export default class NewAlbum extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: false,
      copyText: null,
      updatePublish: false,
      dataOld: {
        title: '',
        description: '',
        status: '',
        images: [],
        company_slug: '',
      },
      formData: {
        title: '',
        description: '',
        status: '',
        images: [],
        company_slug: '',
      },
    };
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updatePublish && prevState.updatePublish !== this.state.updatePublish) {
      setTimeout(() => {
        this.setState({
          updatePublish: false,
        })
      }, 3000);
    }
  }

  loadData() {
    this.setState({
      loading: true,
      error: '',
    });
    API.get(`/api/v1/my-album/${this.props.match.params.id}`)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        data.images = JSON.parse(data.images);
        const dataOld = JSON.parse(JSON.stringify(data));
        this.setState({
          loading: false,
          formData: data,
          dataOld: dataOld,
        });
      })
      .catch((error) => {
        console.log('load album error', error);
        return this.setState({
          loading: false,
          error: error.message,
        });
      })
  }

  updateData(obj) {
    this.setState({
      loading: true,
      error: '',
    });
    obj.status = this.state.formData.status;
    API.put(`/api/v1/my-album/${this.props.match.params.id}`, obj)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        data.images = JSON.parse(data.images);
        const updatePublish = (this.state.formData.status === "publish" && this.state.dataOld.status !== data.status);
        const dataOld = JSON.parse(JSON.stringify(data));
        return this.setState({
          edit: false,
          loading: false,
          formData: data,
          dataOld: dataOld,
          updatePublish: updatePublish,
        });
      })
      .catch((error) => {
        console.log('update error', error);
        return this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong album'
        });
      })
  }

  handleSubmit = (event, images) => {
    event.preventDefault();
    const form = document.querySelector('#form');
    let obj = serialize(form, {hash: true, empty: true});
    obj.images = JSON.stringify(images);
    this.updateData(obj);
    event.stopPropagation();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.state.error}
        </Alert>
      )
    } else if (this.state.loading) {
      return (
        <Alert
          severity="success"
          icon={<CircularProgress color="inherit" size={20}/>}
        >
          Loading...
        </Alert>
      )
    }
  };

  handleChange = (event) => {
    let formData = {...this.state.formData};
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData,
      error: '',
    });
  };

  updateImage = (data) => {
    if (this.state.edit) {
      let formData = {...this.state.formData};
      formData.images = data;
      this.setState({
        formData: formData,
      });
    }
  }

  handleCopy = (id) => {
    let copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    if (document.execCommand("copy")) {
      document.execCommand("copy")
      console.log('copyText >>>', copyText.value)
    }
    this.setState({
      copyText: copyText.value,
    }, () => {
      setTimeout(() => {
        this.setState({
          copyText: null,
        })
      }, 3000);
    });
  }

  render() {
    let formData = {...this.state.formData};
    let images = (formData.images) ? [...formData.images] : [];

    return (
      <Panel loading={this.state.loading} pageTitle={'Album'} {...this.props}>
        <Grid item xs={12}>
          {(this.state.edit) && this.renderError()}
          <ValidatorForm
            noValidate
            autoComplete='off'
            id={'form'}
            instantValidate={false}
            onSubmit={(e) => {
              this.handleSubmit(e, images)
            }}
            className={'albumForm'}
            ref={this.form}
            method="POST"
          >
            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              <Grid item xs={2} sm={1}>
                <IconButton
                  component={RouterLink}
                  to={`/albums`}
                  aria-label="toggle"
                  disabled={this.state.loading}
                >
                  <IoIosArrowBack/>
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              <Grid item xs={8} sm={10}>
                <TextValidator
                  required
                  fullWidth
                  autoFocus
                  margin="dense"
                  id={'title'}
                  name={'title'}
                  disabled={this.state.loading || !this.state.edit}
                  value={formData.title || ''}
                  type={'text'}
                  label={'Album Name'}
                  placeholder={'Give a catchy name to entice people to view and share'}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment
                      position="end">
                      <IconButton
                        color={'primary'}
                        aria-label="toggle"
                        disabled={this.state.loading}
                        onClick={() => {
                          this.setState({
                            edit: !this.state.edit,
                          });
                        }}
                      >
                        <IoMdCreate/>
                      </IconButton>
                    </InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            { /*
              (!this.state.edit) &&
              <div className={'contentPaper'}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography component="h3" variant="h6">Social Media:</Typography>
                    <Typography component="div">
                      <Badge color="default" badgeContent={0} className={'viewsBadge'} showZero>
                        <Typography>Facebook</Typography>
                      </Badge>
                    </Typography>
                    <Typography component="div">
                      <Badge color="default" badgeContent={0} className={'viewsBadge'} showZero>
                        <Typography>Twitter</Typography>
                      </Badge>
                    </Typography>
                    <Typography component="div">
                      <Badge color="default" badgeContent={0} className={'viewsBadge'} showZero>
                        <Typography>Instagram</Typography>
                      </Badge>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography component="h3" variant="h6">Website Views:</Typography>
                    <Typography component="div">
                      <Badge color="default" badgeContent={0} className={'viewsBadge'} showZero>
                        <Typography>Manual Url</Typography>
                      </Badge>
                    </Typography>
                    <Typography component="div">
                      <Badge color="default" badgeContent={0} className={'viewsBadge'} showZero>
                        <Typography>Website</Typography>
                      </Badge>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
           */}

            <Typography component="h5" variant="h6" style={{paddingTop: '1em'}}>
              Album Images:
              <IconButton
                color={'primary'}
                aria-label="toggle"
                disabled={this.state.loading}
                onClick={() => {
                  this.setState({
                    edit: !this.state.edit,
                  });
                }}
                style={{
                  position: 'relative',
                  right: 0,
                  top: 0,
                }}
              >
                <IoMdCreate/>
              </IconButton>
            </Typography>

            <AlbumImagesBox
              {...this.props}
              edit={this.state.edit}
              obj={(images) ? images : {}}
              loading={this.state.loading}
              delete={(obj) => {
                if (this.state.edit) {
                  images.splice(obj, 1);
                }
              }}
              update={this.updateImage}
            />

            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              <Grid item xs={8} sm={10}>
                <TextValidator
                  fullWidth
                  margin="dense"
                  id={'content'}
                  name={'description'}
                  disabled={this.state.loading || !this.state.edit}
                  value={formData.description || ''}
                  type={'textarea'}
                  label={'Description (optional)'}
                  multiline={true}
                  placeholder={'This will show under your album name and'}
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment
                      position="start">
                      <IconButton
                        color={'primary'}
                        aria-label="toggle"
                        disabled={this.state.loading}
                        onClick={() => {
                          this.setState({
                            edit: !this.state.edit,
                          });
                        }}
                        style={{
                          position: 'relative',
                          right: 0,
                          top: 0,
                        }}
                      >
                        <IoMdCreate/>
                      </IconButton>
                    </InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              style={{paddingBottom: '1em'}}
            >
              <Grid item xs={8} sm={10}>
                {(this.state.edit) ?
                  <>
                    {
                      <>
                        <Typography component="h5" variant="h6" style={{paddingTop: '1em'}}>
                          URL for this album:
                        </Typography>
                        <TextField
                          fullWidth
                          name={'slug'}
                          disabled={this.state.loading || !this.state.edit || formData.status !== "publish"}
                          onChange={this.handleChange}
                          value={formData.slug || (!this.state.edit) ? formData.id : ''}
                          InputProps={{
                            startAdornment: <InputAdornment
                              position="start">https://traca247.com/company/{formData.company_slug}/</InputAdornment>,
                            endAdornment: <InputAdornment
                              position="end">
                              <IconButton
                                color={'primary'}
                                aria-label="toggle"
                                disabled={this.state.loading}
                                onClick={() => {
                                  this.setState({
                                    edit: !this.state.edit,
                                  });
                                }}
                              >
                                <IoMdCreate/>
                              </IconButton>
                            </InputAdornment>,
                          }}
                        />
                      </>
                    }
                  </>
                  :
                  <>
                    <Typography component="h5" variant="h6" style={{paddingTop: '1em'}}>
                      URL for this album:
                    </Typography>
                    <input
                      style={{display: 'none'}}
                      id="slugCopy"
                      value={`https://traca247.com/company/${formData.company_slug}/${formData.slug || (!this.state.edit) ? formData.id : ''}`}
                      type={'text'}
                      onChange={()=>{}}
                    />
                    <Input
                      fullWidth
                      disabled={this.state.loading || !this.state.edit || formData.status !== "publish"}
                      onClick={() => {
                        this.handleCopy('slugCopy');
                      }}
                      value={`https://traca247.com/company/${formData.company_slug}/${formData.slug || (!this.state.edit) ? formData.id : ''}`}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle"
                            onClick={() => {
                              this.handleCopy('slugCopy');
                            }}
                          >
                            <RiFileCopyLine/>
                          </IconButton>
                          <IconButton
                            color={'primary'}
                            aria-label="toggle"
                            disabled={this.state.loading}
                            onClick={() => {
                              this.setState({
                                edit: !this.state.edit,
                              });
                            }}
                          >
                            <IoMdCreate/>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Collapse in={(!!this.state.copyText)}>
                      <Alert iconMapping={{success: <MdCheckCircle fontSize="inherit"/>}}>
                        url copied. Now you can: ctrl+v: {this.state.copyText}
                      </Alert>
                    </Collapse>
                  </>
                }
              </Grid>
            </Grid>

            <Box className={'alignRight'} style={{paddingTop: '2em'}}>
              {(!this.state.edit) ?
                <>
                  <Collapse in={this.state.updatePublish}>
                    <Alert
                      iconMapping={{success: <MdCheckCircle fontSize="inherit"/>}}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({
                              updatePublish: false,
                            });
                          }}
                        >
                          <IoMdClose fontSize="inherit"/>
                        </IconButton>
                      }
                    >
                      Your Album has been published to your
                      account: {`https://traca247.com/company/${this.state.formData.company_slug}/${this.state.formData.slug || (!this.state.edit) ? formData.id : ''}`} Now
                      click the share button to start marketing your work.
                    </Alert>
                  </Collapse>

                  <Button
                    disabled={this.state.loading || this.state.edit}
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{marginLeft: '1em'}}
                    onClick={() => {
                      let formData = {...this.state.formData};
                      formData['status'] = (formData.status === "drafts") ? "publish" : "drafts";
                      this.setState({
                        formData: formData,
                        error: '',
                      });
                    }}
                  >
                    {(formData.status === "publish") ? 'Private Album' : 'Public Album'}
                    <BiRightArrow style={{marginLeft: '.5em'}}/>
                  </Button>
                  {
                    (formData.status !== "drafts") &&
                    <AlbumShareMenu
                      loading={this.state.loading}
                      match={this.props.match}
                      url={`https://traca247.com/company/${formData.company_slug}/${formData.slug || formData.id}`}
                      title={formData.title}
                      content={formData.description}
                      images={formData.images}
                    />
                  }
                </>
                :
                <>
                  <Button
                    disabled={this.state.loading}
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{marginLeft: '1em'}}
                    onClick={() => {
                      let formData = {...this.state.formData};
                      formData['status'] = (formData.status === "drafts") ? "publish" : "drafts";
                      this.setState({
                        formData: formData,
                        error: '',
                      });
                    }}
                  >
                    {(formData.status === "publish") ? 'Private Album' : 'Public Album'}
                    <BiRightArrow style={{marginLeft: '.5em'}}/>
                  </Button>

                  <Button
                    disabled={this.state.loading}
                    style={{marginLeft: '1em'}}
                    type="reset"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      const newData = {...this.state.dataOld};
                      this.setState({
                        edit: !this.state.edit,
                        formData: newData,
                      });
                    }}
                  >
                    Cancel
                    <BiRightArrow style={{marginLeft: '.5em'}}/>
                  </Button>
                  <Button
                    style={{marginLeft: '1em'}}
                    disabled={this.state.loading}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                    <BiRightArrow style={{marginLeft: '.5em'}}/>
                  </Button>
                </>
              }
            </Box>
          </ValidatorForm>
        </Grid>
      </Panel>
    );
  }
}
