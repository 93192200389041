import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link as RouterLink} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import {
  AiFillStar,
  AiTwotoneSetting,
  BiLogOut,
  BsFillLightningFill,
  FaComments,
  IoIosNotifications,
  IoMenu,
  MdDashboard,
  RiFolderUserFill
} from "react-icons/all";
import Logo from '../../assets/images/logo_t.png';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LinearProgress from "@material-ui/core/LinearProgress";
import './style.scss';

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function PageWrapper(props) {
  let user = props.auth.getUser();
  let notifications = (!user.verify) ? 1 : 0;
  if (!user.company_id) {
    notifications = notifications + 1;
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(JSON.parse(localStorage.getItem('menu')) || true);

  const menu = [
    {
      title: 'Main Menu',
    },
    {
      title: 'Albums',
      link: '/albums',
      icon: <MdDashboard/>
    },
    {
      title: 'Social Accounts',
      link: '/social-accounts',
      icon: <FaComments/>
    },
    {
      title: 'Notifications',
      link: '/notifications',
      icon: <BsFillLightningFill/>,
      badge: {
        type: 'secondary',
        value: notifications,
      }
    },
    {
      disabled: props.disabledProfile,
      title: 'Company profile',
      link: '/profile',
      icon: <RiFolderUserFill/>
    },
    {
      disabled: true,
      title: 'Settings',
      link: '/settings',
      icon: <AiTwotoneSetting/>,
      badge: {
        type: 'secondary',
        value: 'New',
      }
    },
    {
      title: 'Upgrade',
      link: '#',
      icon: <AiFillStar/>
    },
    {
      title: 'Logout',
      link: '/logout',
      icon: <BiLogOut/>
    },
  ];

  const handleDrawerOpen = () => {
    localStorage.setItem('menu', !open);
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar
        color={'inherit'}
        position="fixed"
        className={clsx(classes.appBar, `appHeader`, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={''}>
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <IconButton
              color="inherit"
              to={'/notifications'}
              component={RouterLink}
            >
              <Badge badgeContent={notifications} color="secondary">
                {<IoIosNotifications/>}
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, 'page-drawer', {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={`${classes.toolbar} toolbar-header`}>
          {
            (open) &&
            <Typography variant="h6" noWrap>
              <img src={Logo} alt={''} className={'page-logo'}/>
            </Typography>
          }
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="end"
          >
            <IoMenu/>
          </IconButton>
        </div>

        <List>
          {menu.map((obj, i) => (
            (obj.link) ?
              <ListItem
                button
                key={i}
                to={obj.link}
                component={RouterLink}
              >
                {
                  (obj.icon) &&
                  <ListItemIcon>
                    {obj.icon}
                  </ListItemIcon>
                }
                <ListItemText
                  primary={obj.title}
                />
                {
                  (obj.badge) &&
                  <Badge
                    badgeContent={obj.badge.value}
                    color={obj.badge.type || 'secondary'}
                  />
                }
              </ListItem>
              :
              <ListItem
                key={i}
                component="div"
              >
                {
                  (obj.icon) &&
                  <ListItemIcon>
                    {obj.icon}
                  </ListItemIcon>
                }
                {
                  (open) &&
                  <ListItemText
                    className={`${(!obj.icon) && 'noIcon'}`}
                    primary={obj.title}
                  />
                }
                {
                  (obj.badge) &&
                  <Badge
                    badgeContent={obj.badge.value}
                    color={obj.badge.type || 'secondary'}
                  />
                }
              </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={'page-main'}>
        {(props.loading) ? <LinearProgress className={'mainLinearProgress'}/> : ''}
        <div className={'appBarSpacer'}/>
        <Container maxWidth="lg" className={''}>

          {(props.pageTitle) &&
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                color="primary"
                gutterBottom
                className={'pageHeaderTitle'}
                dangerouslySetInnerHTML={{__html: props.pageTitle}}
              />
            </Grid>
          </Grid>
          }

          <Paper elevation={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{padding: '.5em 2.5em'}}>
                  {props.children}
                </div>
              </Grid>
            </Grid>
            <Box pt={4}/>
          </Paper>
        </Container>
        <div className={'appBarSpacer'}/>
      </main>
    </div>
  );
}
