import React, {Component} from "react";
import './Profile.css'
import Grid from "@material-ui/core/Grid";
import API from "../../Auth/api";
import ProfileDialogs from "./ProfileDialogs";
import TagsAutocomplete from "./tagsAutocomplete";

export default class Tags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      value: null,
      open: false,
      error: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    API.get(`/api/v1/tags`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: (
            error
            && error.response
            && error.response.data
            && error.response.data.error
          ) ? error.response.data.error
            : 'wrong tags'
        });
        if (error) {
          console.log(error);
        }
      })
  };

  render() {

    let value =  (this.state.data && this.props.object.value && this.props.object.value.length > 0)
      ? this.props.object.value.map(x => this.state.data.find(el => x === el.id))
      : [];

    return (
      <>
        <Grid
          item
          xs={this.props.object.xs}
          md={this.props.object.md}
        >
          <TagsAutocomplete
            {...this.props}
            disabled={true}
            tagOptions={this.state.data || []}
            value={this.state.value || value}
            loading={this.state.loading || this.props.loading}
            handleOpen={(newValue) => {
              this.setState({
                open: !this.state.open,
              })
            }}
            handleChange={(newValue) => {
              this.setState({
                value: newValue,
              });
            }}
          />
          {(this.state.open) &&
            <ProfileDialogs
              {...this.props}
              open={this.state.open}
              handleClose={() => {
                this.setState({
                  open: false,
                });
              }}
              tagOptions={this.state.data || []}
              value={value}
              loading={this.state.loading || this.props.loading}
              handleOpen={() => {
                this.setState({
                  open: !this.state.open,
                })
              }}
              handleChange={(newValue) => {
                this.setState({
                  value: newValue,
                });
              }}
            />
          }
        </Grid>
      </>
    );
  }
}
