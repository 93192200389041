import React, {Component} from "react";
import Panel from "../../Panel";
import './style.scss'
import AlbumsContent from "./AlbumsContent";

export default class Albums extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {

    return (
      <Panel loading={this.state.loading} pageTitle={null} {...this.props}>

        <AlbumsContent auth={this.props.auth}/>

      </Panel>
    );
  }
}
